import { getField, updateField } from 'vuex-map-fields'
import { uri } from '~/api/uri'

export const mapComputedSearchFields = (id, fields) => {
  const res = {
    idStr () {
      return `${this.type}-${id}-${this.idx || 0}`
    }
  }
  for (const fieldName of fields) {
    res[fieldName] = {
      get () {
        const item = this.$store.state.search.fields.find(i => i.id === this.idStr)
        return item && item[fieldName]
      },
      set (value) {
        this.$store.dispatch('search/storeField', {
          id: this.idStr,
          fieldName,
          value
        })
      }
    }
  }
  return res
}

export const initState = {
  fields: []
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  add ({ commit }, { id, type }) {
    commit('add', {
      id,
      type,
      busy: false,
      value: null,
      query: '',
      selected: null,
      results: [],
      meta: null,
      cursor: -1,
      page: 0,
      v: 0
    })
  },
  remove ({ commit }, id) {
    commit('remove', id)
  },
  async init ({ state, commit }, { id, value, page = 1 }) {
    const field = state.fields.find(i => i.id === id)
    const ids = (value && `${value}`.trim() !== '' && `${value}`.trim().split(',')) || []
    if (field) {
      commit('storeField', { id, fieldName: 'busy', value: true })
      commit('storeField', { id, fieldName: 'value', value: ids.join(',') })
      if (ids.length > 0 || field.v > 0) {
        const url = `${uri.search[field.type]}/${ids.join(',')}?page=${page}`
        if (page !== state.page) {
          commit('setPage', { id, page })
        }
        const res = await this.$axios(url)
        if (res && res.data) {
          const data = (res.data && res.data.data) || res.data
          if (field.value instanceof Array) {
            commit('storeField', { id, fieldName: 'query', value: '' })
            commit('storeField', {
              id,
              fieldName: 'selected',
              value: data.sort((a, b) => {
                if (a.text < b.text) {
                  return -1
                }
                if (a.text > b.text) {
                  return 1
                }
                return 0
              })
            })
          } else {
            commit('storeField', { id, fieldName: 'query', value: data.text })
            commit('storeField', { id, fieldName: 'selected', value: data })
          }
        }
      } else {
        commit('storeField', { id, fieldName: 'query', value: '' })
        commit('storeField', { id, fieldName: 'selected', value: [] })
        commit('storeField', { id, fieldName: 'results', value: [] })
      }
      if (field.v === 0) {
        commit('storeField', { id, fieldName: 'v', value: 1 })
      }
      commit('storeField', { id, fieldName: 'busy', value: false })
    }
  },
  async load ({ state, commit }, { id, query, page = 1 }) {
    const field = state.fields.find(i => i.id === id)
    if (field && (field.page < page || page === 1)) {
      let q
      if (query === null || query === 'undefined' || typeof query === 'undefined') {
        q = ''
      } else {
        q = query
      }
      commit('setBusy', { id, value: true })
      const url = `${uri.search[field.type]}?q=${q}&page=${page}&skip=${field.value}`
      if (page !== state.page) {
        commit('setPage', { id, page })
      }
      const res = await this.$axios(url)
      if (res && res.data) {
        commit('setResults', { id, value: res.data.data })
        commit('setMeta', {
          id,
          value: {
            page: res.data.current_page,
            lastPage: res.data.last_page,
            total: res.data.total,
            from: res.data.from,
            to: res.data.to
          }
        })
      }
      commit('setBusy', { id, value: false })
    }
  },
  clear ({ state, commit }, { id }) {
    commit('storeField', { id, fieldName: 'selected', value: null })
    commit('storeField', { id, fieldName: 'value', value: null })
  },
  storeField ({ commit }, { id, fieldName, value }) {
    commit('storeField', { id, fieldName, value })
  }
}
export const mutations = {
  updateField,
  add (state, field) {
    const oldField = state.fields.find(i => i.id === field.id)
    if (oldField) {
      field.v = oldField.v + 1
      field.results = []
      field.selected = oldField.selected
      field.value = oldField.value
      field.query = oldField.query
      field.cursor = oldField.cursor
      state.fields.push(field)
    } else {
      state.fields.push(field)
    }
  },
  remove (state, { id, v }) {
    state.fields = state.fields.filter(i => !(i.id === id && i.v === v))
  },
  storeField (state, { id, fieldName, value }) {
    const field = state.fields.find(i => i.id === id)
    if (field) {
      field[fieldName] = value
    }
  },
  setResults (state, { id, value }) {
    const field = state.fields.find(i => i.id === id)
    if (field) {
      if (field.page === 1) {
        field.results = value
      } else {
        field.results = [field.results, value].flat()
      }
    }
  },
  setBusy (state, { id, value }) {
    const field = state.fields.find(i => i.id === id)
    if (field) {
      field.busy = !!value
    }
  },
  setPage (state, { id, page }) {
    const field = state.fields.find(i => i.id === id)
    if (field) {
      field.page = page
    }
  },
  setMeta (state, { id, value }) {
    const field = state.fields.find(i => i.id === id)
    if (field) {
      field.meta = value
    }
  }
}
export const getters = {
  getField,
  results: state => (id) => {
    const field = state.fields.find(i => i.id === id)
    if (field) {
      return state.results
    }
    return []
  },
  meta: state => (id) => {
    const field = state.fields.find(i => i.id === id)
    if (field) {
      return state.meta
    }
    return []
  },
  busy: state => (id) => {
    const field = state.fields.find(i => i.id === id)
    if (field) {
      return state.busy
    }
    return []
  }
}
